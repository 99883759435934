// https://www.talkclassical.com/threads/the-top-500-composers-and-the-111-most-influential-composers.17277/
export const composers = [
  { name: 'Johann Sebastian Bach' },
  { name: 'Wolfgang Amadeus Mozart' },
  { name: 'Ludwig van Beethoven' },
  { name: 'Franz Schubert' },
  { name: 'Johannes Brahms' },
  { name: 'Richard Wagner' },
  { name: 'Giuseppe Verdi' },
  { name: 'George Frideric Handel' },
  { name: 'Joseph Haydn' },
  { name: 'Fryderyk Chopin' },
  { name: "Pyotr Il'yich Tchaikovsky" },
  { name: 'Franz Liszt' },
  { name: 'Robert Schumann' },
  { name: 'Claude Debussy' },
  { name: 'Giacomo Puccini' },
  { name: 'Igor Stravinsky' },
  { name: 'Felix Mendelssohn' },
  { name: 'Richard Strauss' },
  { name: 'Gustav Mahler' },
  { name: 'Maurice Ravel' },
  { name: 'Antonin Dvorak' },
  { name: 'Antonio Vivaldi' },
  { name: 'Gioachino Rossini' },
  { name: 'Sergei Rachmaninov' },
  { name: 'Bela Bartok' },
  { name: 'Benjamin Britten' },
  { name: 'Dmitry Shostakovich' },
  { name: 'Sergei Prokofiev' },
  { name: 'Hector Berlioz' },
  { name: 'George Gershwin' },
  { name: 'Aaron Copland' },
  { name: 'Gabriel Faure' },
  { name: 'Edvard Grieg' },
  { name: 'Arnold Schoenberg' },
  { name: 'Georg Philipp Telemann' },
  { name: 'Jean Sibelius' },
  { name: 'Georges Bizet' },
  { name: 'Gaetano Donizetti' },
  { name: 'Ralph Vaughan Williams' },
  { name: 'Henry Purcell' },
  { name: 'Charles Ives' },
  { name: 'Edward Elgar' },
  { name: 'Nikolay Rimsky-Korsakov' },
  { name: 'Anton Bruckner' },
  { name: 'Johann Jr. Strauss' },
  { name: 'Modest Mussorgsky' },
  { name: 'Erik Satie' },
  { name: 'Niccolo Paganini' },
  { name: 'Krzystof Penderecki' },
  { name: 'Philip Glass' },
  { name: 'Leonard Bernstein' },
  { name: 'Vincenzo Bellini' },
  { name: 'Cesar Franck' },
  { name: 'Claudio Monteverdi' },
  { name: 'Samuel Barber' },
  { name: 'Francis Poulenc' },
  { name: 'Charles Gounod' },
  { name: 'Jules Massenet' },
  { name: 'Arthur Sullivan' },
  { name: 'John Cage' },
  { name: 'Scott Joplin' },
  { name: 'Astor Piazzolla' },
  { name: 'Arvo Part' },
  { name: 'Gustav Holst' },
  { name: 'Arcangelo Corelli' },
  { name: 'Alessandro Scarlatti' },
  { name: 'Johann Pachelbel' },
  { name: 'Johann Christian Bach' },
  { name: 'Camille Saint-Saens' },
  { name: 'Carl Maria von Weber' },
  { name: 'Paul Hindemith' },
  { name: 'Alexander Scriabin' },
  { name: 'Carl Philipp Emanuel Bach' },
  { name: 'Hugo Wolf' },
  { name: 'Oliver Messiaen' },
  { name: 'Leos Janacek' },
  { name: 'Domenico Scarlatti' },
  { name: 'Manuel de Falla' },
  { name: 'Heitor Villa-Lobos' },
  { name: 'Christopher W. R. von Gluck' },
  { name: 'William Byrd' },
  { name: 'Alban Berg' },
  { name: 'Zoltan Kodaly' },
  { name: 'Jacques Offenbach' },
  { name: 'Alexander Borodin' },
  { name: 'Giovanni Pierluigi da Palestrina' },
  { name: 'Darius Milhaud' },
  { name: 'Max Reger' },
  { name: 'Bedrich Smetana' },
  { name: 'Carl Nielsen' },
  { name: 'William Walton' },
  { name: 'Jean-Phillippe Rameau' },
  { name: 'Isaac Albeniz' },
  { name: 'Heinrich Schütz' },
  { name: 'Pietro Mascagni' },
  { name: 'Frederick Delius' },
  { name: 'Percy Grainger' },
  { name: 'Bohuslav Martinu' },
  { name: 'Gyorgy Sandor Ligeti' },
  { name: 'John Dowland' },
  { name: 'Giacomo Meyerbeer' },
  { name: 'Dieterich Buxtehude' },
  { name: 'Ferruccio Busoni' },
  { name: 'Mikhail Glinka' },
  { name: 'Carl Orff' },
  { name: 'Franz Lehar' },
  { name: 'Luigi Boccherini' },
  { name: 'Karlheinz Stockhausen' },
  { name: 'Joaquin Rodrigo' },
  { name: 'Francois Couperin' },
  { name: 'Enrique Granados' },
  { name: 'Elliot Carter' },
  { name: 'Luciano Berio' },
  { name: 'Ottorino Respighi' },
  { name: 'Ernest Bloch' },
  { name: 'Ruggero Leoncavallo' },
  { name: 'Arthur Honegger' },
  { name: 'Alberto Ginastera' },
  { name: 'Alexander Glazunov' },
  { name: 'Lul-Baptiste' },
  { name: 'Aram Khachaturian' },
  { name: 'Pierre Boulez' },
  { name: 'Witold Lutoslawski' },
  { name: 'Toru Takemitsu' },
  { name: 'Edgard Varèse' },
  { name: 'Charpenti-Antoine' },
  { name: 'Girolamo Frescobaldi' },
  { name: 'Karol Szymanowksi' },
  { name: 'Johann Nepomuk Hummel' },
  { name: 'Ned Rorem' },
  { name: 'Arrigo Boito' },
  { name: 'Giovanni Pergolesi' },
  { name: 'Tomaso Albinoni' },
  { name: 'Kurt Weill' },
  { name: 'Anton Webern' },
  { name: 'Orlando di Lasso' },
  { name: 'Josquin Desprez' },
  { name: 'Hans Werner Henze' },
  { name: 'Giovanni Gabrieli' },
  { name: 'Clara Wieck Schumann' },
  { name: 'Tomas Luis de Victoria' },
  { name: 'Max Bruch' },
  { name: 'Fritz Kreisler' },
  { name: 'Leo Delibes' },
  { name: 'Henry Cowell' },
  { name: 'Alfred Schnittke' },
  { name: 'Hildegard of Bingen' },
  { name: 'Alan Hovhaness' },
  { name: 'Louis Spohr' },
  { name: 'Erich Wolfgan Korngold' },
  { name: 'Michael Haydn' },
  { name: 'Morton Feldman' },
  { name: 'Pablo de Sarasate' },
  { name: 'Frank Martin' },
  { name: 'Malcom Arnold' },
  { name: 'Muzio Clementi' },
  { name: 'George Enescu' },
  { name: 'Joaquin Turina' },
  { name: 'Giuseppe Tartini' },
  { name: 'Iannis Xenakis' },
  { name: 'Umberto Giordano' },
  { name: 'Michael Tippett' },
  { name: 'George Crumb' },
  { name: 'Luigi Cherubini' },
  { name: 'Guillaume de Machaut' },
  { name: 'Charles Villiers Stanford' },
  { name: 'Ernest Chausson' },
  { name: 'Amy Marcy Beach' },
  { name: 'Amilcare Ponchielli' },
  { name: 'Anton Rubinstein' },
  { name: 'Jacques Ibert' },
  { name: 'Engelbert Humperdinck' },
  { name: 'Steve Reich' },
  { name: 'Guillaume Dufay' },
  { name: 'Henryk Wieniawski' },
  { name: 'Edouard Lalo' },
  { name: 'Peter Maxwell Davies' },
  { name: 'Virgil Thomson' },
  { name: 'Edward MacDowell' },
  { name: 'John Rutter' },
  { name: 'Orlando Gibbons' },
  { name: 'William Bolcom' },
  { name: 'Emmanuel Chabrier' },
  { name: 'Gian Carlo Menotti' },
  { name: 'Ernst Krenek' },
  { name: 'Marcel Dupre' },
  { name: 'Louis Moreau Gottschalk' },
  { name: 'Albert Roussel' },
  { name: 'Ernst von Dohnanyi' },
  { name: 'John Adams' },
  { name: 'Jan Sweelinck' },
  { name: 'Mily Balakirev' },
  { name: 'Herbert Howells' },
  { name: 'Fernando Sor' },
  { name: 'Carlo Gesualdo' },
  { name: 'Paul Dukas' },
  { name: 'Gerald Finzi' },
  { name: 'Victor Herbert' },
  { name: 'John Tavener' },
  { name: 'William Schuman' },
  { name: 'Lou Harrison' },
  { name: 'Thomas Tallis' },
  { name: 'Manuel Ponce' },
  { name: 'Alexander Zemlinsky' },
  { name: 'Mario Castelnuovo-Tedesco' },
  { name: 'Johann Sr. Strauss' },
  { name: 'Ambroise Thomas' },
  { name: 'Maurice Durufle' },
  { name: 'Marin Marais' },
  { name: 'Vincent Persichetti' },
  { name: 'Fanny Mendelssohn-Hensel' },
  { name: 'Jean Francaix' },
  { name: 'Josef Strauss' },
  { name: 'Domenico Cimarosa' },
  { name: 'Gunther Schuller' },
  { name: 'Johannes Ockeghem' },
  { name: 'Arnold Bax' },
  { name: 'Walter Piston' },
  { name: 'Frank Bridge' },
  { name: 'Charles Tomlinson Griffes' },
  { name: 'Howard Hanson' },
  { name: 'John Harbison' },
  { name: 'Heinrich I. F. von Biber' },
  { name: 'Carlos Chavez' },
  { name: 'Josef Rheinberger' },
  { name: 'Dmitri Kabalevsky' },
  { name: 'Antonio Salieri' },
  { name: 'Thomas Morley' },
  { name: 'John Corigliano' },
  { name: 'Francesco Cilea' },
  { name: 'Norman Dello Joio' },
  { name: 'William Grant Still' },
  { name: 'Hubert Parry' },
  { name: 'Franz von Suppe' },
  { name: 'John Blow' },
  { name: 'Luigi Dallapiccola' },
  { name: 'Reynaldo Hahn' },
  { name: 'Peter Warlock' },
  { name: 'Michael Praetorius' },
  { name: 'Thomas Campion' },
  { name: 'Louis Vierne' },
  { name: 'Hanns Eisler' },
  { name: 'Harrison Birtwistle' },
  { name: 'Wilhelm Friedemann Bach' },
  { name: 'Nikolay Medtner' },
  { name: 'Milton Babbitt' },
  { name: 'Federico Mompou' },
  { name: 'Roger Sessions' },
  { name: 'Reinhold Gliere' },
  { name: 'Heinrich Isaac' },
  { name: 'Thomas Arne' },
  { name: 'Richard Rodney Bennett' },
  { name: 'Carl Loewe' },
  { name: 'Samuel Scheidt' },
  { name: 'John Ireland' },
  { name: 'Lukas Foss' },
  { name: 'John Field' },
  { name: 'Sofia Gubaidulina' },
  { name: 'Morton Gould' },
  { name: 'Sigfrid Karg-Elert' },
  { name: 'Andre Jolivet' },
  { name: 'Antonio Soler' },
  { name: 'Wolfgang Rihm' },
  { name: 'Charles Koechlin' },
  { name: 'Anton Reicha' },
  { name: 'Josef Suk' },
  { name: 'Einojuhani Rautavaara' },
  { name: 'Ignacy Jan Paderewski' },
  { name: 'Thomas Tomkins' },
  { name: 'Roy Harris' },
  { name: 'Hans Erich Pfitzner' },
  { name: 'Peter Sculthorpe' },
  { name: 'Henri Dutilleux' },
  { name: 'Karel Husa' },
  { name: 'Friedrich von Flotow' },
  { name: 'Adolphe Adam' },
  { name: 'Henri Vieuxtemps' },
  { name: 'William Boyce' },
  { name: 'Niels Gade' },
  { name: 'Leo Brouwer' },
  { name: 'George Rochberg' },
  { name: "Vincent d' Indy" },
  { name: 'Francisco Tarrega' },
  { name: 'Rodion Shchedrin' },
  { name: 'Benedetto Marcello' },
  { name: 'Henryk Górecki' },
  { name: 'Giovanni Paisiello' },
  { name: 'Mauro Giuliani' },
  { name: 'Henri Duparc' },
  { name: 'Gian Francesco Malipiero' },
  { name: 'Alexandre Guilmant' },
  { name: 'Luigi Nono' },
  { name: 'Johann Adolf Hasse' },
  { name: 'Antonio Caldara' },
  { name: 'Dominick Argento' },
  { name: 'Giuseppe Torelli' },
  { name: 'Cecile Chaminade' },
  { name: 'Andrea Gabrieli' },
  { name: 'Karl Stamitz' },
  { name: 'Peter Schickele' },
  { name: 'Karl Ditters von Dittersdorf' },
  { name: 'Baldassare Galuppi' },
  { name: 'Daniel Pinkham' },
  { name: 'Stefan Wolpe' },
  { name: 'Anatol Lyadov' },
  { name: 'Jean-Marie Leclair' },
  { name: 'Jehan Alain' },
  { name: 'Libby Larsen' },
  { name: 'Pietro Antonio Locatelli' },
  { name: 'Michael Nyman' },
  { name: 'Samuel Adler' },
  { name: 'Giacomo Carissimi' },
  { name: 'Johann Joseph Fux' },
  { name: 'Charles Wuorinen' },
  { name: 'Silvestre Revueltas' },
  { name: 'Valentin Alkan' },
  { name: 'David Diamond' },
  { name: 'Ruth Crawford' },
  { name: 'Luca Marenzio' },
  { name: 'Arthur Bliss' },
  { name: 'Mauricio Kagel' },
  { name: 'Anton Arensky' },
  { name: 'Friedrich Kuhlau' },
  { name: 'Per Norgard' },
  { name: 'Otto Nicolai' },
  { name: 'Ervin Schulhoff' },
  { name: 'Albert Lortzing' },
  { name: 'Francesco Geminiani' },
  { name: 'Johann Joachim Quantz' },
  { name: 'Franz Berwald' },
  { name: 'Lili Boulanger' },
  { name: 'Joseph Bodinde Boismortier' },
  { name: 'Joseph Jongen' },
  { name: 'Saverio Mercadante' },
  { name: 'Randall Thompson' },
  { name: 'Johann Hermann Schein' },
  { name: 'Jean Langlais' },
  { name: 'Thomas Weelkes' },
  { name: 'Paul Creston' },
  { name: 'György Kurtag' },
  { name: 'Francesco Cavalli' },
  { name: 'Jan Dismas Zelenka' },
  { name: 'Moritz Moszkowski' },
  { name: 'Johann Jakob Froberger' },
  { name: 'Fromental Halevy' },
  { name: 'Matthew Locke' },
  { name: 'Andre-Ernest-Modeste Gretry' },
  { name: 'Bruno Maderna' },
  { name: 'Silvius Leopold Weiss' },
  { name: 'John Bull' },
  { name: 'Joseph Canteloube de Malaret' },
  { name: 'Hans Leo Hassler' },
  { name: 'Lennox Berkeley' },
  { name: 'Agustín Barrios Mangore' },
  { name: 'Franz Schreker' },
  { name: 'Leo Sowerby' },
  { name: 'William Lawes' },
  { name: 'Johann Friedrich Fasch' },
  { name: 'Louis Andriessen' },
  { name: 'Adrian Willaert' },
  { name: 'Gustave Charpentier' },
  { name: 'Edmund Rubbra' },
];
